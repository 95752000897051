/**
 * Callback will be called when the ownerDocument of the node is loaded and parsed (or immediately if called previously).
 * This this is similar to the "DOMContentLoaded" event when requireComplete=false (default) or the "load" event when requireComplete=true.
 * The second waits for until the initial HTML document has been completely loaded and parsed and all dependent resources
 * such as stylesheets and images to become available, while the first (default) will only wait until the initial HTML
 * document has been completely loaded and parsed and may trigger the event before waiting for stylesheets, images, and
 * subframes finish loading.
 *
 * @param node the Node upon which the callback should be called (must be in a document or a Document node itself); defaults to the Window's Document if null
 * @param callback the callback to call when the conditions determined by requireComplete have been satisfied
 * @param requireComplete - Set this optional parameter to "true" if you want the document to be "complete" before callback is called
 *                          (i.e. the whole page has loaded, including all dependent resources such as stylesheets and images)
 */
 export default function onDocumentLoaded<T extends Node>(node: T | null, callback: (node: T | null) => any, requireComplete = false) {
	let doc: Document;
	if (node !== null) {
		if (node instanceof Document) {
			doc = node;
		} else if (node.nodeName === "#document") {
			doc = node as unknown as Document;
		} else if (node.ownerDocument !== null) {
			doc = node.ownerDocument;
		} else {
			throw new Error(`Unable to resolve the document containing for ${node}.`);
		}
	} else {
		doc = document;
	}

	if (
		doc.readyState === "complete"
		|| (!requireComplete && doc.readyState === "interactive")
	) {
		callback.call(node, node);
	} else if (requireComplete) {
		doc.defaultView!.addEventListener("load", () => {
			callback.call(node, node);
		});
	} else {
		doc.addEventListener("DOMContentLoaded", () => {
			callback.call(node, node);
		});
	}
}
import Papaparse from "papaparse";
import onDocumentLoaded from "./script/onDocumentLoaded";

type Artwork = {
	id: string;
	name: string;
	artistLocation: string;
	headshot: string;
	artworkTitle: string;
	moreText: string;
	moreUrl: string;
	pageStyle: string;
	text: string;
	url: string;
	additionalHtml?: string;
}


function getActiveId(): string {
	const activeId = document.body.getAttribute("data-active-id");
	if (activeId === null) {
		throw new Error("No \"a-active-id\" found on document body.");
	}
	return activeId;
}


let artworkData: Array<Artwork> | undefined;

function getArtworkData(artworkId: string): Artwork | null {
	if (artworkData !== undefined) {
		for (let i = 0; i < artworkData.length; i++) {
			const artwork = artworkData[i];
			if (artwork !== undefined && artwork.id === artworkId) {
				return artwork;
			}
		}
	}
	return null;
}

function switchActiveArtworkData(delta: number) {
	if (artworkData !== undefined) {
		const activeId = getActiveId();
		let activeIndex: number | undefined;
		for (let i = 0; i < artworkData.length; i++) {
			const artwork = artworkData[i];
			if (artwork !== undefined && artwork.id === activeId) {
				activeIndex = i;
				break;
			}
		}

		if (activeIndex === undefined) {
			return;
		}

		activeIndex = activeIndex + delta;
		if (activeIndex < 0) {
			activeIndex = artworkData.length - 1;
		} else if (activeIndex >= artworkData.length) {
			activeIndex = 0;
		}

		const artwork = artworkData[activeIndex];
		if (artwork !== undefined) {
			const activeArtworkData = getArtworkData(artwork.id);
			if (activeArtworkData !== null) {
				onDocumentLoaded(
					document,
					() => {
						updatePage(activeArtworkData);
					}
				);
			}
		}
	}
}

Papaparse.parse("/artwork.csv", {
	download: true,
	header: true,
	complete: function(results: any) {
		artworkData = results.data as Array<Artwork>;
		onDocumentLoaded(
			document,
			() => {
					const activeId = getActiveId();
					const activeArtworkData = getArtworkData(activeId);
					updatePage(activeArtworkData);
				}
			);
}
});

window.addEventListener("popstate", (event: PopStateEvent) => {
	updatePage(event.state, true);
});

const pageStylePrefix = "pageStyle--";

function updatePage(data: Artwork | null, isHistoryPop = false) {
	if (data === null) {
		data = {
			id: "",
			name: "Valley Health System in Paramus: Artwork Information",
			artistLocation: "",
			headshot: "",
			artworkTitle: "",
			moreText: "",
			moreUrl: "",
			pageStyle: "",
			text: `There are hundreds of pieces of art throughout the building for patients and staff to enjoy.
				Scan the QR code found next to the artwork for more information on the local artists.`,
			url: "",
			additionalHtml: "<img src=\"/images/valley-health-paramus.jpg\" alt=\"\"/>"
		};
	}

	// Update Location and Page Title
	const pageTitle = data.name.length !== 0 ? data.name : "Artist Information";
	if (!isHistoryPop) {
		const url = window.location.hostname.indexOf("localhost") === -1 ? "/artist/" + data.id : window.location.href;
		window.history.pushState(data, pageTitle, url);
	}
	document.title = pageTitle;

	// Set the active id
	document.body.setAttribute("data-active-id", data.id);

	// Update the style class on the body
	for (let i = 0; i < document.body.classList.length; i++) {
		const styleClass = document.body.classList[i];
		if (styleClass?.indexOf(pageStylePrefix) === 0) {
			document.body.classList.remove(styleClass);
			break;
		}
	}

	const pageStyle = data.pageStyle === "" ? "none" : data.pageStyle;
	document.body.classList.add(pageStylePrefix + pageStyle);

	// Headshot
	const headshot = document.querySelector<HTMLElement>(".masthead__headshot");
	if (headshot !== null) {
		if (data.headshot.length !== 0) {
			headshot.style.backgroundImage = `url('/headshot/${data.headshot}')`;
		} else {
			headshot.style.backgroundImage = "";
		}
	}

	// Body: name, text and moreText/moreUrl
	const body = document.querySelector<HTMLElement>(".body");
	if (body !== null) {
		let artistLocation = "";
		
		if (data.artistLocation.length !== 0) {
			artistLocation += `<p class="body__artistLocation">${data.artistLocation}</p>`;
		}
		
		let more = "";
		if (data.moreText.length !== 0 || data.moreUrl.length !== 0) {
			const moreText = data.moreText.length !== 0 ? data.moreText : data.moreUrl;
			if (data.moreUrl.length !== 0) {
				more += `<p class="body__more"><a href="${data.moreUrl}">${moreText}</a></p>`;
			} else {
				more += `<p class="body__more">${moreText}</p>`;
			}
		}

		const text = data.text.replace(/\n{2}/g, '&nbsp;</p><p>');

		body.innerHTML = `
			<h1>${data.name}</h1>
			${artistLocation}
			<p>${text}</p>
			${data.additionalHtml !== undefined ? data.additionalHtml : ""}
			${more}
		`;
	}
}

document.addEventListener("keydown", (event: KeyboardEvent) => {
	let changeData = 0;
	if (event.key === "ArrowLeft") {
		changeData = -1;
	} else if (event.key === "ArrowRight") {
		changeData = 1;
	}

	if (changeData !== 0) {
		switchActiveArtworkData(changeData);
	}
});